import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'
import { font, color, ease } from '../../layout/global'

import { useScrollPosition } from "../../hooks/useScrollPosition"

import smoothscroll from 'smoothscroll-polyfill'

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

const ScrollLine = forwardRef(({ className, text, toClick }, ref) => {
  const [isVisible, setIsVisible] = useState(true)

  const toScroll = () => {
    let element = document.querySelector(`${toClick}`)
    let offset = -1
    let bodyRect = document.body.getBoundingClientRect().top
    let elementRect = element.getBoundingClientRect().top
    let elementPosition = elementRect - bodyRect
    let offsetPosition = elementPosition - offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  useScrollPosition(
    ({ currPos }) => {
      let position = Math.abs(currPos.y)
      position > 0 ? setIsVisible(false) : setIsVisible(true)
    },
  [])

  return(
    <Wrapper ref={ref} className={className} isVisible={isVisible}>
      <div className="scroll-container" {...toClick && {onClick : toScroll} }>
        <span className="scroll-line"></span>
        <span className="scroll-text">{text}</span>
      </div>
    </Wrapper>
  )
})

export default ScrollLine

const Wrapper = styled.div`
  pointer-events: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover .scroll-text {
    color: ${color.red};
  }
  &:hover .scroll-line::before {
    background: ${color.whiteOpacity};
  }
  &:hover .scroll-line::after {
    background: ${color.red};
  }

  .scroll-container {
    position: relative;
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    justify-items: center;
    align-content: center;
    grid-row-gap: 10px;
    opacity: ${props => props.isVisible ? '1' : '0'};
    transition: all 0.3s ${ease.out};
    pointer-events: all;
  }
  .scroll-text {
    color: ${color.greyOpacity};
    ${font.robotoMedium};
    font-size: 1.1rem;
    text-transform: uppercase;
    line-height: normal;
    text-align: center;
    transition: all 0.3s ${ease.out};
  }

  .scroll-line {
    position: relative;
    height: 30px;
    overflow: hidden;
    width: 2px;
  }


  .scroll-line::before,
  .scroll-line::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    transition: all 0.3s ${ease.out};
  }

  .scroll-line::before {
    background: ${color.greyOpacity};
  }

  .scroll-line::after {
    height: 10px;
    width: 100%;
    background: ${color.red};
    animation: move 2.5s infinite;
  }

  @keyframes move {
    0% {
      transform: translate3d(0, -100%, 0)
    }
    60% {
      transform: translate3d(0, 300%, 0)
    }
    100% {
      transform: translate3d(0, 300%, 0)
    }
  }
`