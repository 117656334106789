import React, { useState, useEffect, useRef } from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { color, font, ease, device } from "../../layout/global"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const CarouselComponent = ({
  showArrows,
  showCustomArrows,
  showIndicators,
  transitionTime,
  slideTime,
  autoPlay,
  id,
  className,
  blue,
  noText,
  delay,
  animationTime,
  carouselContent
}) => {
  const isMounted = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const ref = useRef(null)
  const sliderDelay = delay ? delay : 650

  // ANIMATIONS
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.matchMedia(device.tabletP).matches
    ) {
      let slides = document.querySelectorAll(`#${id} .slide:not(.selected)`)
      let slidesSelected = document.querySelectorAll(`#${id} .slide.selected`)
      let trigger = ref.current
      let tl = gsap
        .timeline({
          id: "carousel",
          paused: true,
          scrollTrigger: {
            trigger: trigger,
            start: "center bottom",
            end: "bottom bottom",
          },
        })
        .fromTo(trigger, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
      !noText &&
        slides.forEach(e => {
          let description = e.querySelector(".description")
          return tl.set([e, description], { opacity: 0 })
        })
      !noText &&
        slidesSelected.forEach(e => {
          let description = e.querySelector(".description")
          return tl.fromTo(
            [e, description],
            { opacity: 0 },
            { opacity: 1, duration: 0.3 },
            0
          )
        })
      tl.to({}, {}, ">+2")
    }
  }, []) // eslint-disable-line
  // ---

  useEffect(() => {
    if (!noText) {
      setTimeout(() => {
        let slideOff = document.querySelectorAll(`#${id} .slide`)
        slideOff.forEach(e => {
          let desc = e.querySelector(".description")
          e.style.opacity = 0
          desc.style.opacity = 0
        })
      }, 0)
      setTimeout(() => {
        let slide = document.querySelectorAll(`#${id} .slide.selected`)
        slide.forEach(e => {
          let desc = e.querySelector(".description")
          e.style.opacity = 1
          desc.style.opacity = 1
        })
      }, sliderDelay)
    }
  }, [currentSlide, noText]) // eslint-disable-line

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  })

  const Next = () => {
    let slide = document.querySelectorAll(`#${id} .slide.selected`)
    slide.forEach(e => {
      let desc = e.querySelector(".description")
      e.style.opacity = 0
      desc.style.opacity = 0
    })
    setTimeout(function() {
      setCurrentSlide(currentSlide + 1)
    }, 150)
  }

  const Prev = () => {
    let slide = document.querySelectorAll(`#${id} .slide.selected`)
    slide.forEach(e => {
      let desc = e.querySelector(".description")
      e.style.opacity = 0
      desc.style.opacity = 0
    })
    setTimeout(function() {
      setCurrentSlide(currentSlide - 1)
    }, 150)
  }

  const updateCurrentSlide = index => {
    if (currentSlide !== index && noText) {
      let slideOff = document.querySelectorAll(`#${id} .slide`)
      slideOff.forEach(e => {
        let desc = e.querySelector(".description")
        e.style.opacity = 0
        desc.style.opacity = 0
      })
      setTimeout(() => {
        currentSlide !== index && setCurrentSlide(index)
      }, animationTime * 1000)
      setTimeout(() => {
        let slide = document.querySelectorAll(`#${id} .slide.selected`)
        slide.forEach(e => {
          let desc = e.querySelector(".description")
          e.style.opacity = 1
          desc.style.opacity = 1
        })
      }, animationTime * 1000 + sliderDelay)
    } else {
      currentSlide !== index && setCurrentSlide(index)
    }
  }

  return (
    <CarouselContainer
      className="carousel-container"
      id={id}
      ref={ref}
      blue={blue}
      showCustomArrows={showCustomArrows}
    >
      <LeftArrow onClick={Prev} id="carousel-prev" className="control-arrows" />
      <RightArrow
        onClick={Next}
        id="carousel-next"
        className="control-arrows"
      />
      <Wrapper
        showArrows={showArrows}
        showIndicators={showIndicators}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={autoPlay}
        animationTime={animationTime}
        transitionTime={transitionTime}
        interval={slideTime}
        className={className}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        blue={blue}
      >
        {carouselContent['sliders'].map((i, index) => (
          <div className="container" key={index}>
            <div className="image">
              {i.image && (
                <img 
                    src={i.image} 
                    alt='carousel' 
                    style={{
                        objectFit:'contain', 
                        objectPosition:'center center'
                    }}
                />
              )}
            </div>
            <div className="description">
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: i.title }}
              ></h2>
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: i.text }}
              ></p>
            </div>
          </div>
        ))}
      </Wrapper>
    </CarouselContainer>
  )
}

export default CarouselComponent

const Wrapper = styled(Carousel)`
  background-color: #000;

  .carousel .slide {
    background: #000;
    transition: all
      ${props => (props.animationTime ? props.animationTime : "0.15")}s
      ${ease.out};
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .container,
  .image > .gatsby-image-wrapper {
    height: 100%;
  }
  .container {
    position: relative;
  }
  .description {
    position: absolute;
    top: ${props => (props.sliderButton ? "35%" : "50%")};
    left: 18%;
    transform: translateY(-40%);
    opacity: 0;
    transition: all 0.15s ${ease.out};
    transition-delay: 0.15s;
  }
  .title {
    width: 550px;
    color: ${color.white};
    z-index: 1;
    ${font.robotoBold};
    font-size: 2.2rem;
    text-align: left;
  }
  .text {
    margin-top: 20px;
    width: 525px;
    color: ${color.white};
    z-index: 1;
    ${font.robotoRegular};
    font-size: 1.8rem;
    text-align: left;
  }
  span {
    color: ${props => (props.blue ? "#07c7ed" : color.red)};
  }
  .image > .gatsby-image-wrapper {
    opacity: 0;
    transition: all 0.15s ${ease.out};
    transition-delay: 0.3s;
  }
  .carousel .slide.selected .description {
    transform: translateY(-60%);
    opacity: 1;
  }
  .carousel .slide.selected .image > .gatsby-image-wrapper {
    opacity: 1;
  }
  .control-dots .dot.selected {
    background: ${props => (props.blue ? "#07c7ed" : color.red)};

    &:hover {
      background: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }
  .control-dots {
    bottom: 140px;
  }
  .control-dots .dot {
    background: ${color.grey};
    transition: all 0.3s ${ease.out};
    width: 50px;
    height: 3px;
    box-shadow: none;
    border-radius: 0%;
    position: relative;

    &:hover {
      background: ${color.greyDark};
    }

    &:focus {
      outline: 0;
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: transparent;
      width: 100%;
      height: 1200%;
      top: 0;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 1440px) {
    .description {
      left: 10%;
    }
  }
`

export const CarouselContainer = styled.div`
  background-color: #000;
  position: relative;

  #carousel-prev {
    left: 0;
    margin-left: 40px;
  }
  #carousel-next {
    right: 0;
    margin-right: 40px;
  }
  .control-arrows {
    display: ${props => (props.showCustomArrows === false ? "none" : "block")};
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    width: 30px;
    fill: ${color.white};
    transition: all 0.3s ${ease.out};
    padding: 5px;

    &:hover {
      fill: ${props => (props.blue ? "#07c7ed" : color.red)};
      cursor: pointer;
    }
  }
`
