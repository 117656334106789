import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import SubmitButton from "../SubmitButton"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import PinSVG from "../../images/Contactos/pin.svg"
import TlmSVG from "../../images/Contactos/tlm.svg"
import EmailSVG from "../../images/Contactos/email.svg"
import WhatsAppSVG from "../../images/Contactos/whatsapp.svg"

import { font, color, device } from "../../layout/global"

import { handleClick } from "../../utils/custom"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Contactos = ({ misc, lang, br, contentMsg }) => {
  const triggerRef = useRef(null),
    titleRef = useRef(null),
    pin1 = useRef(null),
    pin2 = useRef(null),
    pin3 = useRef(null),
    p1 = useRef(null),
    p2 = useRef(null),
    p3 = useRef(null),
    p4 = useRef(null),
    p5 = useRef(null),
    p6 = useRef(null),
    p7 = useRef(null),
    p8 = useRef(null),
    subtitleRef = useRef(null),
    buttonRef = useRef(null)
  var paux = [p1, p2, p3, p4, p5, p6, p7, p8];
  useEffect(() => {
    let Pin1 = pin1.current,
      Pin2 = pin2.current,
      Pin3 = pin3.current

    gsap
      .timeline({
        paused: true,
        scrollTrigger: {
          anticipatePin: 1,
          trigger: triggerRef.current,
          toggleActions: "play none none none",
          start: "center 80%",
        },
      })
      .fromTo(
        titleRef.current,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.15 }
      )
      .fromTo(
        [Pin1, Pin2, Pin3],
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.15, stagger: 0.15 }
      )
      .fromTo(
        [p1.current, p2.current, p3.current, p4.current, p5.current, p6.current, p7.current, p8.current],
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.15, stagger: 0.05 }
      )
      .fromTo(
        subtitleRef.current,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.3 },
        ">"
      )
      .fromTo(
        buttonRef.current,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.3 },
        "<"
      )
  }, [])

  const breakpoints = useBreakpoint()

  return (
    <Wrapper ref={triggerRef} className="contacts-wrapper" br={lang}>
      <div className="title" ref={titleRef}>
        <h3>
          {misc.intro1}
          <br />
          {misc.intro2}
          <br />
          {misc.intro3}
        </h3>
      </div>
      <div className="contacts">
        {/** col1 */}
        <a
          href={
            misc.col1.info[0].href
          }
          rel="noreferrer noopener"
          style={{ textAlign: "center" }}
          target="_blank"
        >
          <div className="address" ref={pin1}>
            <img src={misc.col1.type} alt="Logo" className="pin-contacts" />
            {misc.col1.info.map((item, i) =>
              <span ref={paux[i]} key={i}>{item.display}</span>
            )}
          </div>
        </a>
        {/** col2 */}
        <div>
          <a onClick={() => handleClick(misc.col2.info[0].href, lang)}
            rel="noreferrer noopener"
            target={lang === 'br' ? `_blank` : `_self`}
            className="phone-anchor"
          >
            <img src={misc.col2.type} alt="Logo" className="tlm-contacts" />
          </a>
          <div className="phone" ref={pin2}>
            {misc.col2.info.map((item, i) =>
              <a onClick={() => handleClick(item.href, lang)}
                key={'tel' + i} ref={paux[i + 3]} style={{ textAlign: "center" }}>
                <span>{item.display}</span>
                {item.display === '+351 234 630 200' &&
                  <><br /><span><small style={{ fontSize: "1vh" }}>{contentMsg.fixo}</small></span></>}</a>
            )}
          </div>
        </div>
        {/** col3 */}
        <div className="email">
          <a href={misc.col3.info[0].href} rel="noreferrer noopener">
            <EmailSVG className="email-contacts" />
          </a>
          <div className="email" ref={pin3}>
            {misc.col3.info.map((item, i) =>
              <a href={item.href} key={'email' + i} ref={paux[i + 5]}>
                <span>{item.display}</span></a>
            )}
          </div>
        </div>
      </div>
      {!breakpoints.tl && lang === 'br' && (
        <div className="sub-title" ref={subtitleRef}>
          <h4>{misc.text1}</h4>
        </div>
      )}
      {!breakpoints.tl &&
        lang === 'br' && (
          <a
            href="https://ramalhosbrasil.auvo.com.br/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <SubmitButton
              text={misc.text2}
              ref={buttonRef}
              className="submit-btn"
            />
          </a>
        ) /* : (
        <Link to={`/${lang}/aftersales`}>
          <SubmitButton
            text={misc.text2}
            ref={buttonRef}
            className="submit-btn"
          />
        </Link>
      ) */}
    </Wrapper>
  )
}

export default Contactos

const Wrapper = styled.div`
  background-color: #ced2d3;
  padding: 110px 20px;

  a{
    cursor: pointer;
  }

  /* .phone-anchor {
    display: ${props => (props.br === 'br' ? "none" : "block")};
  } */

  .title h3 {
    ${font.robotoMedium};
    color: #97999b;
    margin: 0 3%;

    @media ${device.mobileP} {
      font-size: 1.6rem;
    }

    @media ${device.tabletP} {
      font-size: 1.8rem;
    }
  }

  .contacts {
    @media ${device.mobileP} {
      display: flex;
      flex-direction: column;
      margin: 30px 0;
    }

    @media ${device.tabletP} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-width: 950px;
      margin: 150px auto;
    }

    a {
      color: #434343;

      @media ${device.mobileP} {
        margin: 30px 0;
      }

      @media ${device.tabletP} {
        margin: 0;
      }
    }

    a:hover {
      color: ${color.red};
    }

    span {
      ${font.roboto400};
      font-size: 1.3rem;
    }

    .address,
    .phone,
    .email {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .tlm-contacts,
    .email-contacts {
      width: 25px;
      height: auto;
      fill: #d9000d;
      margin: auto;

      @media ${device.mobileP} {
        padding-bottom: 20px;
      }

      @media ${device.tabletP} {
        padding-bottom: 40px;
      }
    }

    .tlm-contacts {
      width: 24px;
      margin: auto;
    }

    .pin-contacts {
      width: 20px;
      height: auto;
      fill: #d9000d;
      margin: auto;

      @media ${device.mobileP} {
        padding-bottom: 20px;
      }

      @media ${device.tabletP} {
        padding-bottom: 40px;
      }
    }
  }

  .sub-title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    h4 {
      color: #414643;
      ${font.robotoMedium}
      font-size:1.3rem;
      letter-spacing: 0px;
    }
  }

  .submit-btn {
    display: block;
    margin: 0 auto;
  }
`
