import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { color, font, device } from "../../layout/global"
import CTAScroll from "../CTA-Scroll"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Hero = ({ text, image, color, hasFilter, animateIn, animateOut, animateSemiOut, toClick, br, brLink }) => {
  const triggerRef = useRef(null),
    titleRef = useRef(null),
    subtitleRef = useRef(null),
    imageRef = useRef(null),
    miniImgRef = useRef(),
    ctaRef = useRef(null)

  useEffect(() => {
    if (animateIn) {
      let tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          anticipatePin: 1,
          trigger: triggerRef.current,
          pin: false,
          toggleActions: "play none none none",
          start: "top center",
        },
      })
      tl.set(titleRef.current, { opacity: 0, y: 15 })
      tl.set(subtitleRef.current, { opacity: 0, y: 15 })
      tl.set(imageRef.current, { opacity: 0 })
      br && tl.set(miniImgRef.current, { opacity: 0 })
      tl.set(ctaRef.current, { opacity: 0 })
      tl.to(titleRef.current, { opacity: 1, y: 0, duration: 0.3, ease: "Power0.easeNone" }, 1)
      tl.to(subtitleRef.current, { opacity: 1, y: 0, duration: 0.3, ease: "Power0.easeNone" }, ">-0.15")
      br && tl.to(miniImgRef.current, { opacity: 1, duration: 0.3 }, ">")
      tl.to(ctaRef.current, { opacity: 1, duration: 0.3 }, ">")
      if (animateSemiOut) {
        tl.to(imageRef.current, { opacity: 0.5, duration: 0.5 }, ">-0.15")
      } else {
        tl.to(imageRef.current, { opacity: 1, duration: 0.5 }, ">-0.15")
      }
    }

    if (animateOut) {
      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            anticipatePin: 1,
            trigger: triggerRef.current,
            toggleActions: "resume none reverse none",
            start: "center center",
            end: "+=100% bottom",
          },
        })
        .to({}, {}, ">+1.5")
    }

    if (animateSemiOut) {
      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            anticipatePin: 1,
            trigger: triggerRef.current,
            pin: true,
            toggleActions: "resume none reverse none",
            start: "center center",
            end: "bottom bottom",
          },
        })
        .to({}, {}, ">")
    }
  }, [animateIn, animateOut, animateSemiOut, br])

  return (
    <Wrapper ref={triggerRef} color={color} hasFilter={hasFilter}>
      <div id="about-hero-banner">
        <div className="image-container" ref={imageRef}>
          {image && (
            <img
              src={image}
              alt="hero"
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
            />
          )}
        </div>
        <div className="text-container">
          <h2 className="title" ref={titleRef} dangerouslySetInnerHTML={{ __html: text.title }}></h2>
          <h3 className="subtitle" ref={subtitleRef} dangerouslySetInnerHTML={{ __html: text.subtitle }}></h3>
          {br && (
            <div className="nau-container" ref={miniImgRef}>
              <a href={brLink} target="_blank" rel="noreferrer">
                <img
                  src={br}
                  alt="hero"
                  style={{
                    objectFit: "contain",
                    objectPosition: "center center",
                  }}
                />
              </a>
            </div>
          )}
        </div>
      </div>
      {toClick && <CTAScroll ref={ctaRef} className="cta" text="scroll" toClick={toClick} />}
    </Wrapper>
  )
}

export default Hero

Hero.propTypes = {
  text: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  color: PropTypes.string,
  filter: PropTypes.bool,
}

const Wrapper = styled.section`
  #about-hero-banner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media ${device.mobileP} {
      min-height: 100vh;
    }

    @media ${device.tabletP} {
      max-height: 100vh;
    }
  }

  .image-container {
    height: 100%;
  }

  .image-container {
    & img {
      object-fit: cover !important;
      height: 100%;
      width: 100%;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .image-container .image {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    & img {
      pointer-events: none;
    }

    ${props =>
      props.hasFilter &&
      css`
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          background-color: #00000080;
        }
      `}
  }

  .nau-container {
    margin: 50px auto 0 auto;
    width: 175px;
    height: auto;
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    text-align: center;

    @media ${device.mobileP} {
      padding: 0 20px;
    }

    @media ${device.tabletP} {
      padding: initial;
    }
  }

  .title {
    ${font.robotoMedium};
    color: ${props => (props.color ? props.color : `${color.greyDark}`)};
    letter-spacing: 1px;

    @media ${device.mobileP} {
      font-size: 4rem;
      line-height: 1.2;
    }

    @media ${device.tabletP} {
      font-size: 6rem;
      line-height: 1.7;
    }
  }

  .subtitle {
    ${font.financierLightI};
    color: ${props => (props.color ? props.color : `${color.red}`)};
    line-height: 1;

    @media ${device.mobileP} {
      font-size: 4rem;
      margin-top: 2rem;
      letter-spacing: 0.1px;
    }

    @media ${device.tabletP} {
      font-size: 6.5rem;
      margin-top: -1rem;
      letter-spacing: -2.5px;
    }
  }

  .cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 2.5%;

    @media screen and (max-width: 1023px) {
      margin-bottom: 5rem;
    }

    & .scroll-text {
      color: ${props => !props.hasFilter && color.greyDark};
    }
    & .scroll-line::before {
      background: ${props => !props.hasFilter && color.greyDark};
    }
  }
`
